// @file Generate a greeting message for our users
import { captureMessage } from '@@/bits/error_tracker'
import { __ } from '@@/bits/intl'
import { safeLocalStorage } from '@@/bits/safe_storage'
import type { User } from '@@/types'
import { sample } from 'lodash-es'

// Fallback message: one for each day
const dayBasedMessages = [
  __('Happy Sunday!'),
  __('Happy Monday!'),
  __('Happy Tuesday!'),
  __('Happy Wednesday!'),
  __('Happy Thursday!'),
  __('Happy Friday!'),
  __('Happy Saturday!'),
]

// Fallback message: can we used any day
const genericMessage = [
  __('Remember to eat your veggies!'),
  __('Seize the carpe diem!'),
  __('Gentle reminder: you are beautiful.'),
  __('Keep up the good work.'),
  __("Hope you're having a magical day!"),
  __("Don't forget to drink water."),
  __("Hope you're having a good day!"),
]

const lastGenericMessageKey = 'lastGenericMessage'

const timezoneBasedMessages = function (): string[] {
  let timezone = ''
  try {
    // The extracted timezone names are based on the 'IANA Time Zone Database'
    timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    if (typeof timezone !== 'string') {
      timezone = ''
    }
  } catch (e) {
    captureMessage('Error extracting timezone', { context: { error: e } })
  }

  switch (timezone) {
    case /^Australia/.exec(timezone)?.input:
      // Australian message
      return [__('Watch out for Drop Bears.')]
    default:
      return []
  }
}

const lastFallbackMessageGenerationDay = function (val?: number): number {
  if (val) {
    safeLocalStorage.setItem('lastGenericMessageGenerationDay', String(val))
    return val
  } else {
    const storedValue = safeLocalStorage.getItem('lastGenericMessageGenerationDay')
    if (storedValue) {
      return parseInt(storedValue, 10)
    } else {
      return -1
    }
  }
}

const lastFallbackMessage = function (val?: string): string {
  if (val) {
    safeLocalStorage.setItem(lastGenericMessageKey, val)
    return val
  } else {
    const storedValue = safeLocalStorage.getItem(lastGenericMessageKey)
    return storedValue || ''
  }
}

const generateAndSaveAFallbackMessage = function (): string {
  /**
   * We have three kinds of fallback messages:
   *    - day based
   *    - timezone based
   *    - generic (can be used any time)
   * We mix the timezone based message in with the generic messages and sample,
   * then sample between the result and the day based message.
   */
  const today = new Date()
  const todayIndex = today.getDay()
  const dayMessage = dayBasedMessages[todayIndex]

  const timezoneMessage = sample(timezoneBasedMessages())
  if (timezoneMessage) {
    genericMessage.push(timezoneMessage)
  }

  const gmSample = sample(genericMessage)
  const greeting = sample([dayMessage, gmSample])

  lastFallbackMessageGenerationDay(todayIndex)
  lastFallbackMessage(greeting)
  return greeting ?? ''
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const greet = function (user: User): string {
  const today = new Date()
  const hours = today.getHours()
  const signedUp = user?.created_at ? new Date(user.created_at) : new Date()
  const daysElapsed = Math.floor((+today - +signedUp) / 86400000)

  // if user less than a day old, welcome them
  if (daysElapsed <= 1) return __('Welcome to Padlet!')

  // if it's user's anniversary wish them happy anniversary
  if (daysElapsed % 365 === 0) return __('Happy %{num} year anniversary on Padlet!', { num: daysElapsed / 365 })

  // if user is working late at night, acknowledge it
  if (hours >= 0 && hours < 5) return __('Burning the midnight oil, huh?')

  // if user is working early in the morning, acknowledge it
  if (hours >= 5 && hours < 7) return __("Someone's up early!")

  // show one of the generic messages
  // note that generic messages are retained for the whole day
  const todayIndex = today.getDay()
  const lgmday = lastFallbackMessageGenerationDay()
  const lgm = lastFallbackMessage()
  if (lgmday && +lgmday == todayIndex && lgm) {
    // if a generic message was generated today, return it
    return lgm
  } else {
    return generateAndSaveAFallbackMessage()
  }
}

function clearGreetingFromStorage(): void {
  safeLocalStorage.removeItem(lastGenericMessageKey)
  safeLocalStorage.removeItem('lastGenericMessageGenerationDay')
}

export { clearGreetingFromStorage, greet, lastGenericMessageKey }
